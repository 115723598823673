import { IonCard } from "@ionic/react";
import React from "react";

const Card: React.FC = ({ children }) => {
  return (
    <IonCard
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTopWidth: "10px",
        borderTopStyle: "solid",
        borderTopColor: "#ea837c",
      }}
    >
      {children}
    </IonCard>
  );
};

export default Card;
