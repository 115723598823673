import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IonCol, IonLabel } from "@ionic/react";
import ReactInputMask from "react-input-mask";

interface Props {
  name: string;
  label?: string;
  mask: string;
  maskPlaceholder: string;
  size?: string;
  readOnly?: boolean;
}
const InputMask: React.FC<Props> = ({
  name,
  label,
  mask,
  maskPlaceholder,
  size,
  readOnly,
  ...rest
}) => {
  const inputRef = useRef<ReactInputMask>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
        ref.current.setInputValue(value);
      },
      clearValue: (ref, value) => {
        if (value) {
          ref.current.value = value;
          ref.current.setInputValue(value);
        } else {
          ref.current.value = "";
          ref.current.setInputValue("");
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <ReactInputMask
        className="mask"
        ref={inputRef}
        defaultValue={defaultValue}
        name={name}
        mask={mask}
        disabled={readOnly}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default InputMask;
